@mixin mobile{
  @media (max-width: 720px){
    @content;
  }
}

@import 'flags.scss';

html, body, #root{
  padding: 0;
  margin: 0;
  font-family: "Roboto","Twemoji Mozilla",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
  "EmojiOne Color",
  "Android Emoji",
  sans-serif;
}

header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2000;
  color: white;
  display: flex;
  flex-direction: row;
  background-color: #22a340cb;
  backdrop-filter: blur(15px);
  justify-content: space-around;
  .logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;
    color: white;
    img{
      width: 100px;
      @include mobile{
        width: 50px;
      }
    }
    h1{
      margin-right: 10px;
      font-size: 30px;
      @include mobile{
        margin: 20px 10px;
        font-size: 25px;
      }
    }
  }

  nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
    transition: transform 0.5s;
    @include mobile{
      z-index: 3000;
      position: fixed;
      right: 0;
      top: 0;
      width: 80vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #1c1d1c;
      transform: translate(80vw, 0);
    }
    ul{
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      li{
        display: flex;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        //border-left: 2px solid #616161;
        // &:nth-child(1){
        //   //border: none;
        //   @include mobile{
        //     margin-top: 200px;
        //   }
        // }
        a{
          text-align: center;
          transition: all 0.5s;
          text-decoration: none;
          color: white;
          padding: 10px;
          font-weight: 600;
          border-radius: 3px;
          &:hover{
            border-bottom: 1px solid white;
            //box-shadow: 0 0 3px #616161bd;
          }
        }
      }
    }

    .toggleLanguage{
      display: none;
      .toggle{
        color: white;
        border: 1px solid white;
        .togglebutton{
          color: white;
          &.Mui-selected{
            background-color: #fff;
            color: black;
          }
        }
      }
      @include mobile{
        display: flex;

      }
    }

    @include mobile{
      ul{
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        //justify-content: flex-start;
        align-items: center;
        li{
          display: flex;
          margin: 15px 0;
          a{
            text-decoration: none;
            color: white;
            padding: 10px;
            font-weight: 600;
            //box-shadow: 0 0 3px rgba(0,0,0,0.5);
            border-radius: 3px;
          }
        }
      }
    }

    &.activate{
      transform: translate(0, 0);
      display: flex;
      justify-content: center;
    }
  }

  .burger{
    display: none;
    z-index: 3001;
    flex-direction: column;
    padding: 5px;
    margin: auto;
    margin-right: 10px;
    background-color: unset;
    border: none;
    transition: transform 0.5s;
    cursor: pointer;
    @include mobile{
      display: flex;
    }
    .elt{
      transition: all 0.5s;
      transform: rotate(0);
      display: block;
      min-width: 31px;
      width: 31px;
      min-height: 3px;
      height: 3px;
      border-radius: 2px;
      margin: 5px 0;
      background-color: #fff;
      &:nth-child(2){
        //opacity: 0;
      }
    }
    &.activate{
      transform: translate(-55vw, 0);
      .elt{
        &:nth-child(1){
          transform: translate(0, 13px) rotate(45deg);
        }
        &:nth-child(3){
          transform: translate(0, -13px) rotate(-45deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
      }
    }
  }

  .languageSelector{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 30px;
    @include mobile{
      display: none;
    }
    .MuiInputBase-root{
      border: 1px solid white;
    }
  }

  
}

#menu-{
  z-index: 9999!important;
}
// .MuiPaper-root{
//   z-index: 9999!important;
// }

.awssld__bullets{
  transform: translate(0, -50px);
  button{
    border-radius: 3px;
    height: 5px;
    width: 20px;
  }
}
.App{
  display: flex;
  flex-direction: column;

  .page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding: 0;

    .main{
      display: flex;
      flex-direction: column;
      width: 100%;
      .logo{
        width: 60px;
      }
      &._1{
        div{
          max-height:700px;
          @include mobile{
            height: 600px;
          }
          h4{
            z-index: 999;
            width: 80%;
            text-align: center;
            transform: translateX(-50%);
            font-size: 30px;
            font-family: 'Times New Roman', Times, serif;
            position: absolute;
            bottom: 0;
            color: rgb(73, 214, 30);
            @include mobile{
              font-size: 18px;
            }
          }
        }

      }
      &._5{
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        width: calc(100% - 2*0px);
        align-items: center;
        background-color: #414141;
        color: white;
        justify-content: center;
        p{
          width: 60%;
        }
        @include mobile{
          padding: 20px 0 0 0;
          width: calc(100% - 2*0px);
          p{
            width: 90%;
          }
        }
      }

      &._3{
        display: flex;
        flex-direction: column;
        padding: 0px;
        width: calc(100% - 2*0px);
        align-items: flex-start;
        background-color: #414141;
        color: white;
        justify-content: center;
        @include mobile{
          padding: 0px;
          width: calc(100% - 2*0px);
        }
        h2{
          margin-top: 80px;
          width: 100%;
          text-align: center;
        }
        .title{
          display: flex;
          width: 100%;
          justify-content: stretch;
          flex-direction: row;
          margin: 20px 0 100px 0;
          @include mobile{
            flex-direction: column;
            justify-content: stretch;
            align-items: center;
            margin: 0;
          }
          .left-flex{
            display: flex;
            align-items: center;

            margin-left: 40px;
            .img{
              display: flex;
              border-radius: 6px;
              padding: 10px;
              transform: rotate(8deg);
              background-color: #22a340;
              img{
                max-width: 20vw;
                border-radius: 6px;
                transform: rotate(-8deg);
              }
            }
            @include mobile{
              justify-content: center;
              margin: 40px 0;
              .img{
                max-width: 70%;
                transform: rotate(4deg);
                img{
                  max-width: unset;
                  width: 100%;
                  transform: rotate(-4deg);
                }
              }
            }
          }
          .right-flex{
            margin-left: 40px;
            h2{

            }
            p{
              color: #d1d1d1;
            }
            .bubbles{
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
              .bubble{
                border: 1px solid #a3a3a3;
                background-color: #303030;
                border-radius: 9px;
                padding: 20px;
                margin: 40px 20px 0 20px;
                flex: 1;
                img{
                  width: 40px;
                }
              }
            }
            @include mobile{
              margin: 0;
              padding: 20px 0;
              width: 100%;
              .bubbles{
                display: flex;
                flex-direction: column;
              }
              h2{
                margin: 0 20px;
                text-align: center;
              }
            }
          }
        }

        
      }

      &._2{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        padding: 100px 0;
        @include mobile{
          flex-direction: column;
        }
        h5 {
          font-size: 30px;
          color: #333;
          margin-bottom: 10px;
          @include mobile{
            font-size: 25px;
          }
        }
        ul {
          list-style-type: none;
          padding: 0;
          column-count: 2;
        }
        li {
          font-size: 20px;
          color: #666;
          margin: 5px;
        }
        // li::before {
        //   content: '';
        //   display: inline-block;
        //   width: 20px;
        //   height: 20px;
        //   background-size: contain;
        //   margin-right: 5px;
        // }
        .carte{

          max-width: 60vw;
          @include mobile{
            width: 100%;
            max-width: 100%;
            display: flex;
          }
        }
        .left-flex{
          display: flex;
          flex-direction: column;
          padding: 50px;
          .carte{
            display: none;
          }

          @include mobile{
            padding: 10px;
            .carte{
              display: block;
            }
          }
        }
        .right-flex{
          max-width: 60vw;
          display: flex;
          align-items: center;
          justify-content: center;
          //width: 100%;
          @include mobile{
            width: 100vw;
            display: none;
          }
        }
      }

      &._4{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #19642b;
        width: calc(100% - 2*20px);
        padding: 20px;
        color: white;
        min-height: 30vh;
        .support{
          border-radius: 9px;
          width: calc(100% - 2*20px);
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .bubble{
            
            padding: 20px;
            border-radius: 9px;
            //min-width: 80%;
            //display: flex;
            gap: 20px;
            justify-content: space-around;

            display: grid;
            grid-template-columns: repeat(4, minmax(250px, 1fr)); /* Maximum 3 columns with equal width */
          
            @include mobile{
              display: flex;
              flex-direction: column;
            }

            .contact{
              display: flex;
              flex-direction: column;
              width: 100%;
              font-size: 12px;
              margin-top: 10px;
              p{
                display: flex;
                align-items: center;
                img{
                  min-width: 20px;
                  max-width: 20px;
                  margin-right: 7px;
                  //height: 30px;
                }
              }

            }
            .description{
              display: flex;
              font-size: 20px;
              font-family: "Timmana", sans-serif;
              font-style: italic;
              justify-content: center;
              align-items: center;
            }
            .avatar{
              .contact{
                width: unset;
                align-items: center;
              }
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //margin: 0 20px;
              @include mobile{
                margin-bottom: 30px;
              }
              img{
                width: 400px;
                border-radius: 50%;
              }
              h3, p{
                padding: 0;
                margin: 0;
              }
              h3{
                font-size: 1.5em;
                margin-bottom: 30px;
                @include mobile{
                  margin-bottom: 20px;
                }
              }
              p{
                color: #b9b9b9;
                text-align: center;
              }
            }
          }

        }
      }
    }

    .apropos{
      display: flex;
      flex-direction: column;
      width: 100%;
      .awesomeSlider{
        div{
          max-height: 600px;
        }
      }
      &._1{
        background-color: #4d4d4d;
        .pillow{
          @include mobile{
            display: none;
          }
        }
        img{
          display: none;
          width: max-content;
          height: 100%;
          border-radius: 10px;
          @include mobile{
            border-radius: 0;
            min-width: max-content;
            height: 800px;
          }
        }
        .flex-center{
          //overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: "Timmana", sans-serif;
          font-weight: 400;
          font-style: normal;
          
          h2{
            text-align: center;
            z-index: 100;
            font-size: 90px;
            //transform: translate(0, 300px);

            color: white;
            @include mobile{
              //transform: translate(0,200px);
              font-size: 50px;
            }
            span{
              color: #22a340;
            }
          }
        }
      }
      &._3{
        h1{
          text-align: center;
        }
        p{
          padding: 0 30px;
          line-height: 30px;
        }
        .logos{
          height: 50px;
          &.logo1{
            height: 100px;
          }
          &.logo3{
            height: 80px;
          }
          @include mobile{
            margin-bottom: 20px;
          }
        }
        padding: 50px 0;
        color: white;
        background-color: #303130;
        .testimonials-container {
            padding-top: 16px;

            padding-bottom: 32px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
        }
        
        .content-wrapper {
            width: 80%;
            text-align: center;
            h1{
              font-weight: 600;
            }
            h4{
              font-weight: 400;
              color: #bbb9b9;
            }
        }
        
        
        .grid-container {
          padding: 20px;
          display: grid;
          grid-template-columns: repeat(3, minmax(250px, 1fr)); /* Maximum 3 columns with equal width */
          gap: 16px;
          @include mobile{
            grid-template-columns: repeat(1, minmax(250px, 1fr)); /* Maximum 3 columns with equal width */
          }
        }

        .grid-item {
          display: flex;
          align-items: stretch;
        }

        .card {
          flex: 1;
          border: 1px solid #a3a3a3;  
          background-color: #414141;
          padding: 16px;
          border-radius: 8px;
        }
        
      }

      &._2{
        padding: 100px 0 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        min-height: 80vh;
        font-size: 0.9em;
        // background-image: url("../images/camionparking.jpeg");
        background-image: url("../images/flotte/6.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color: white;
        h1{
          font-size: 60px;
          font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
          span{
            color: #22a340;
          }
          @include mobile{
            margin: 20px;
          }
        }
        #logoCollection{
          p{
            font-size: 1.5em;
            color: rgb(223, 223, 223);
          }
        }
        p{
          padding: 10px;
          //backdrop-filter: blur(1px);
          font-size: 1.3em;
          font-weight: 700;
          //background-color: #1c1d1c;
          padding: 20px;
          text-align: center;
        }
        .logos{
          width: 100px;
        }
      }

      &._4{
        display: flex;
        max-width: 100%;
        flex-direction: row;
        background-color: #000000;
        align-items: center;
        justify-content: center;
        padding: 10px;
        div{
          max-width: 100vw;
          video{
            max-width: 100vw;
            background-color: #000000;
          }
        }
        @include mobile{
          flex-direction: column;
        }
      }

      &._5{
        display: flex;
        h2{
          font-size: 30px;
          text-align: center;
          margin: 50px 0 0 0;
        }
        .flex{
          display: flex;
          flex-direction: row;
          align-items: center;
          .left-flex{
            width: 50%;
            transform: translate(-50%, 5px);
          }
        }
      }
    }

    .condition{
      display: flex;
      max-width: 95%;
      &._1{
        flex-direction: column-reverse;
        align-items: center;
        display: flex;
        .left-flex{
          img{
          width: 100%;
          }
        }
        .right-flex{
          padding: 20px;
          text-align: justify;
          text-justify: inter-word;
          h1{
            text-align: center;
          }
        }
      }
    }

    .stockage{
      width: 90%;
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
      }
      h3{
        color: #313131;
        font-size: 20px;
      }
      img{
        width: 50%;
        border-radius: 7px;
        margin: auto;
      }

      span{
        color: green;
        font-weight: 600;
      }

      .images{
        display: grid;
        grid-template-columns: repeat(2,1fr); /* Maximum 3 columns with equal width */
        gap: 16px;
        img{
          //width: 40%;
          width: 100%;
          height: 40vh;
          object-fit:cover;
        }
        @include mobile{
          grid-template-columns: repeat(1,1fr);
          img{
            //width: 40%;
            width: 100%;
            height: auto;
            object-fit:unset;
          }
        }
      }

      &._1{
        align-items: center;
        img{
          width: 80%;
        }
        p{
          font-size: 20px;
        }
        .center{
          width: 80%;
        }
        @include mobile{
          width: 100%;
        }
      }

      &._2{
        padding: 30px;
        background-color: #303030;
        color: white;
        width: calc(100% - 2*30px);
        h3{
          color: #a3a3a3;
        }

        .div-sep{
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 20px;
          @include mobile{
            flex-direction: column;
          }
          .sep{
            flex: 1;
            p{
              text-align: justify;
              width: 100%;
            }
          }
        }
      }
    }
    
    h3.flotte{
      width: 100%;
      margin: 0;
      padding: 30px 0 0 0;
      background-color: #303030;
      color: white;
      text-align: center;
    }

    .flotte{
      
      width: 90%;
      display: flex;
      flex-direction: column;
      @include mobile{
        width: 100%;
      }

      .images{
        display: flex;
        gap: 16px;
        margin: 20px;
        img{
          //width: 40%;
          //max-width: 100%;
          max-height: 50vh;
          border-radius: 7px;
          border: 1px solid rgba(0, 0, 0, 0.527);
        }

        @include mobile{
          flex-direction: column;
          max-width: 100%;
          max-height: unset;
        }

      }

      h1{
        @include mobile{
          padding: 10px;
          &.title{
            text-align: center;
          }
        }
      }
      h3{
        color: #313131;
      }
      .image{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 78%;
          &.longtruck{
            width: 100%;
          }
          border-radius: 7px;
        }
        @include mobile{
          width: 100%;
          img{
            width: 78%;
            &.longtruck{
              width: 100%;
            }
          }
        }
      }

      &._1{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          padding: 0 20px;
        }
        // @include mobile{
        //   padding: 
        // }
      }

      &.article{
        width: calc(100% - 2*30px);
        padding: 100px 30px;
        min-height: 50vh;
        justify-content: space-around;
        align-items: center;
        display: flex;
        flex-direction: row;

        background-color: #303030;
        color: white;
        ul{
          margin: 0;
          padding: 0;
          li{
            display: block;
            margin: 10px 0;
          }
        }
        @include mobile{
          flex-direction: column;
          min-height: 40vh;
          padding: 40px 30px;
        }
      }

      &.articlei{
        // width: calc(100% - 2*30px);
        // padding: 100px 30px;
        // justify-content: space-evenly;
        // align-items: center;
        // display: flex;
        // flex-direction: row-reverse;
        // @include mobile{
        //   flex-direction: column;
        //   min-height: 40vh;
        //   padding: 40px 30px;
        // }

        width: calc(100% - 2*30px);
        padding: 100px 30px;
        min-height: 50vh;
        justify-content: space-around;
        align-items: center;
        display: flex;
        flex-direction: row;

        background-color: #303030;
        color: white;
        ul{
          margin: 0;
          padding: 0;
          li{
            display: block;
            margin: 10px 0;
          }
        }
        @include mobile{
          flex-direction: column;
          min-height: 40vh;
          padding: 40px 30px;
        }
      }
    }
  }

  footer{
    background-color: #1c1d1c;
    color: white;
    width: calc(100% - 2*20px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .infos{
      font-family: Arial, sans-serif;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      font-size: 0.8em;
      img{
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      p{
        display: flex;
        align-items: center;
      }
      

      .left{
        display: flex;
        flex-direction: column;
        margin-right: 30px;

      }

      .right{
        display: flex;
        flex-direction: column;
        margin-left: 30px;

      }
      @include mobile{
        flex-direction: column;
        .right, .left{
          margin: 0;
        }
      }
    }

    .line{
      display: block;
      width: calc(100vw - 2*20px);
      height: 2px;
      min-height: 2px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.5);
    }
    a{
      text-decoration: none;
      color: white;
      font-weight: 600;
    }
    padding-bottom: 30px;
    .left-flex{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mobile{
        flex-direction: column;
      }
      .logo{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        img{
          width: 50px;
        }
        h1{
          margin-right: 10px;
        }
      }

    }
    .description{
      display: flex;
      flex-direction: row;
      align-items: center;
      h4{
        margin-right: 10px;
      }
      p{
        @include mobile{
          font-size: 0.8em;
        }
      }
    }
  }
}

.pillow{
  display: block;
  width: 100%;
  min-width: 100%;
  height: 100px;
  min-height: 100px;
}